const foodCategories = ['Entree', 'Side Dish', 'Dressing', 'Sauce', 'Seasoning'];

const loginFormSchema = [
  {
    type: 'text',
    label: 'Email',
    name: 'email',
    // help: 'Please use a valid email address',
    validation: 'bail|required|email',
  },
  {
    type: 'password',
    label: 'Password',
    name: 'password',
    validation: 'required',
  },
  {
    component: 'div',
    class: 'flex align-center justify-between',
    children: [
      {
        type: 'submit',
        label: 'Login',
      },
      {
        type: 'button',
        name: 'forgot-password',
        label: 'Forgot Password',
      },
    ],
  },
];

const createAccountFormSchema = [
  {
    type: 'text',
    label: 'Name',
    name: 'name',
    validation: 'required',
  },
  {
    type: 'text',
    label: 'Username',
    name: 'username',
    validation: 'required',
  },
  {
    type: 'text',
    label: 'Email',
    name: 'email',
    // help: 'Please use a valid email address',
    validation: 'bail|required|email',
  },
  {
    type: 'password',
    label: 'Password',
    name: 'password',
    validation: 'required',
  },
  {
    type: 'submit',
    label: 'Create Account',
  },
];

const forgotPasswordFormSchema = [
  {
    type: 'text',
    label: 'Email',
    name: 'email',
    // help: 'Please use a valid email address',
    validation: 'bail|required|email',
  },
  {
    type: 'submit',
    label: 'Send Reset',
  },
];

const importFormSchema = [
  {
    type: 'text',
    label: 'Recipe URL',
    name: 'recipeUrl',
    validation: 'required|url',
  },
  {
    type: 'submit',
    label: 'Import Recipe',
  },
];

const addRecipeFormSchema = [
  {
    component: 'div',
    id: 'div-one',
    class: 'grid grid-cols-1 sm:grid-cols-2 sm:gap-6',
    children: [
      {
        type: 'text',
        label: 'Title',
        name: 'title',
        validation: 'required',
      },
      {
        type: 'select',
        label: 'Category',
        name: 'category',
        // validation: 'required',
        options: foodCategories,
      },
    ],
  },
  {
    type: 'text',
    label: 'Description',
    name: 'description',
    validation: 'required',
  },
  {
    component: 'div',
    id: 'div-two',
    class: 'grid grid-cols-2 gap-6',
    children: [
      {
        type: 'group',
        name: 'ingredients',
        validation: 'min:1,length',
        repeatable: true,
        'add-label': '+ Add Ingredient',
        value: [{}],
        children: [
          {
            component: 'div',
            class: 'flex ingredients',
            children: [
              {
                type: 'text',
                name: 'item',
                label: 'Ingredient',
                validation: 'required',
              },
              {
                type: 'hidden',
                name: 'completed',
                value: false,
              },
            ],
          },
        ],
      },
      {
        type: 'group',
        name: 'directions',
        validation: 'min:1,length',
        repeatable: true,
        'add-label': '+ Add Direction',
        value: [{}],
        children: [
          {
            component: 'div',
            class: 'flex directions',
            children: [
              {
                type: 'text',
                name: 'text',
                label: 'Direction',
                validation: 'required',
              },
              {
                type: 'hidden',
                name: 'completed',
                value: false,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    component: 'div',
    id: 'div-three',
    class: 'flex items-center justify-center pt-4 border-t border-gray-300',
    children: [
      {
        type: 'submit',
        label: 'Add Recipe',
      },
    ],
  },
];

const updateRecipeFormSchema = [
  {
    component: 'div',
    id: 'div-one',
    class: 'grid grid-cols-2 gap-6',
    children: [
      {
        type: 'text',
        label: 'Title',
        name: 'title',
        validation: 'required',
      },
      {
        type: 'select',
        label: 'Category',
        name: 'category',
        // validation: 'required',
        options: foodCategories,
      },
    ],
  },
  {
    type: 'text',
    label: 'Description',
    name: 'description',
    validation: 'required',
  },
  {
    component: 'div',
    id: 'div-two',
    class: 'grid grid-cols-2 gap-6',
    children: [
      {
        type: 'group',
        name: 'ingredients',
        validation: 'min:1,length',
        repeatable: true,
        'add-label': '+ Add Ingredient',
        value: [{}],
        children: [
          {
            component: 'div',
            class: 'flex ingredients',
            children: [
              {
                type: 'text',
                name: 'item',
                label: 'Ingredient',
                validation: 'required',
              },
              {
                type: 'hidden',
                name: 'completed',
                value: false,
              },
            ],
          },
        ],
      },
      {
        type: 'group',
        name: 'directions',
        validation: 'min:1,length',
        repeatable: true,
        'add-label': '+ Add Direction',
        value: [{}],
        children: [
          {
            component: 'div',
            class: 'flex directions',
            children: [
              {
                type: 'text',
                name: 'text',
                label: 'Direction',
                validation: 'required',
              },
              {
                type: 'hidden',
                name: 'completed',
                value: false,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    component: 'div',
    id: 'div-three',
    class: 'flex items-center justify-center pt-4 border-t border-gray-300',
    children: [
      {
        type: 'submit',
        label: 'Update Recipe',
      },
    ],
  },
];

const useFormSchemas = () => {
  return {
    loginFormSchema,
    createAccountFormSchema,
    forgotPasswordFormSchema,
    importFormSchema,
    addRecipeFormSchema,
    updateRecipeFormSchema,
  };
};

export default useFormSchemas;
