<template>
  <div class="userProfile">
    <div class="bg-gray-100">
      <div class="py-6 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div class="px-4 py-5 mt-6 bg-white shadow sm:rounded-lg sm:p-6">
          <div class="md:grid md:grid-cols-3 md:gap-6">
            <div class="md:col-span-1">
              <h3 class="text-lg font-medium leading-6 text-gray-900">Profile</h3>
              <p class="mt-1 text-sm leading-5 text-gray-500">
                Your email is used to login to your account. Your username is what will be displayed
                when creating recipes.
              </p>

              <div v-if="errorMsg" class="p-4 mt-4 rounded-md bg-red-50">
                <div class="flex">
                  <div class="flex-shrink-0">
                    <svg class="w-5 h-5 text-red-400" fill="currentColor" viewBox="0 0 20 20">
                      <path
                        fill-rule="evenodd"
                        d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </div>
                  <div class="ml-3">
                    <h3 class="text-sm font-medium leading-5 text-red-800">
                      Error Updating Profile
                    </h3>
                    <div class="mt-2 text-sm leading-5 text-red-700">
                      <p>{{ errorMsg }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="userProfile" class="mt-5 md:mt-0 md:col-span-2">
              <form action="#" method="POST">
                <div class="grid grid-cols-6 gap-6">
                  <div class="col-span-6 sm:col-span-4">
                    <label for="name" class="block text-sm font-medium leading-5 text-gray-700"
                      >Name</label
                    >
                    <input
                      id="name"
                      v-model.trim="name"
                      :placeholder="userProfile.name"
                      class="block w-full px-3 py-2 mt-1 transition duration-150 ease-in-out border border-gray-300 rounded-md shadow-sm form-input disabled:opacity-75 disabled:text-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
                    />
                  </div>

                  <div class="col-span-6 sm:col-span-4">
                    <label for="username" class="block text-sm font-medium leading-5 text-gray-700"
                      >Username</label
                    >
                    <input
                      id="username"
                      v-model.trim="username"
                      :placeholder="userProfile.username"
                      class="block w-full px-3 py-2 mt-1 transition duration-150 ease-in-out border border-gray-300 rounded-md shadow-sm form-input disabled:opacity-75 disabled:text-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
                    />
                  </div>

                  <div class="col-span-6 sm:col-span-4">
                    <label
                      for="email_address"
                      class="block text-sm font-medium leading-5 text-gray-700"
                      >Email address</label
                    >
                    <p class="font-medium leading-5 text-gray-700">{{ user.email }}</p>
                    <!--<input
                      id="email_address"
                      v-model="user.email"
                      disabled
                      class="block w-full px-3 py-2 mt-1 transition duration-150 ease-in-out border border-gray-300 rounded-md shadow-sm form-input disabled:cursor-not-allowed disabled:opacity-75 disabled:text-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
                    />-->
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="px-4 py-3 mt-4 text-right bg-gray-50 sm:px-6">
            <button
              @click="updateProfile"
              :disabled="!updateDisabled"
              class="px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out bg-blue-700 border border-transparent rounded-md shadow-sm disabled:cursor-not-allowed disabled:bg-blue-500 hover:bg-blue-500 focus:outline-none focus:shadow-outline-blue active:bg-blue-700"
            >
              Update Profile
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { db, serverTimestamp } from '@/services/firebase';
import handleErrorCode from '@/util/handleErrorCode';

export default {
  name: 'UserProfile',
  metaInfo: {
    title: 'Profile | Recipeazy',
  },
  data() {
    return {
      name: '',
      username: '',
      updateSuccess: false,
      errorMsg: '',
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      userProfile: (state) => state.userProfile,
    }),
    updateDisabled() {
      return (
        (this.name !== '' && this.name !== this.userProfile.name) ||
        (this.username !== '' && this.username !== this.userProfile.username)
      );
    },
  },
  methods: {
    async updateProfile() {
      try {
        await db
          .collection('users')
          .doc(this.user.uid)
          .update({
            name: this.name !== '' ? this.name : this.userProfile.name,
            username: this.username !== '' ? this.username : this.userProfile.username,
            lastUpdated: serverTimestamp(),
          });
        this.$swal({
          icon: 'success',
          text: `Profile Updated!`,
        });
        this.resetForm();
      } catch (err) {
        if (err.code) {
          this.errorMsg = handleErrorCode(err.code);
        } else {
          this.errorMsg = err;
        }
      }
    },
    resetForm() {
      this.name = '';
      this.username = '';
      this.updateSuccess = false;
      this.errorMsg = '';
    },
  },
};
</script>

<style></style>
