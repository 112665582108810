<template>
  <div class="edit-recipe">
    <div v-if="recipeLoading" class="text-center">
      <LoadingSvg />
    </div>
    <div v-else-if="!recipeLoading && isEmpty(recipe)">
      <div class="py-6 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div class="p-4 rounded-md bg-yellow-50 w-">
          <div class="flex">
            <div class="flex-shrink-0">
              <svg class="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20">
                <path
                  fill-rule="evenodd"
                  d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
            <div class="ml-3">
              <h3 class="text-sm font-medium leading-5 text-yellow-800">Recipe Not Found</h3>
              <div class="mt-2 text-sm leading-5 text-yellow-700">
                <p>We can't seem to find that recipe. Please try a different one</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="max-w-screen-xl px-4 py-12 mx-auto sm:px-6 lg:py-16 lg:px-8">
        <div class="text-center">
          <h2 class="text-3xl font-extrabold leading-9 text-gray-900 sm:text-4xl sm:leading-10">
            Edit Recipe
          </h2>
          <p class="mt-1 mb-2 text-sm leading-5 text-gray-500">Change your ingredients as needed</p>
        </div>

        <div class="mt-6">
          <div class="px-4 sm:px-0">
            <transition name="fade" mode="out-in">
              <div v-if="errorMsg" class="p-4 my-4 border border-red-200 rounded-md bg-red-50">
                <div class="flex">
                  <div class="flex-shrink-0">
                    <svg class="w-5 h-5 text-red-400" fill="currentColor" viewBox="0 0 20 20">
                      <path
                        fill-rule="evenodd"
                        d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </div>
                  <div class="ml-3">
                    <h3 class="text-sm font-medium leading-5 text-red-800">Error Editing Recipe</h3>
                    <div class="mt-2 text-sm leading-5 text-red-700">
                      <p>{{ errorMsg }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </div>

        <div class="">
          <div class="mb-4 overflow-hidden shadow sm:rounded-md">
            <div class="px-4 py-6 bg-white sm:p-8">
              <FormulateForm
                name="edit-recipe-form"
                v-model="recipeForm"
                :schema="schema"
                @submit="handleUpdateRecipe"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isEmpty } from 'lodash';
import { db, serverTimestamp } from '@/services/firebase';
import { handleErrorCode, normalizeRecipe } from '@/util';
import LoadingSvg from '@/components/LoadingSvg.vue';
import useFormSchemas from '@/lib/formSchemas';
const { updateRecipeFormSchema } = useFormSchemas();

export default {
  name: 'EditRecipe',
  components: {
    LoadingSvg,
  },
  metaInfo: {
    title: 'Edit Recipe | Recipeazy',
  },
  data() {
    return {
      errorMsg: '',
      recipe: {},
      recipeForm: {},
      recipeLoading: false,
      schema: updateRecipeFormSchema,
    };
  },
  beforeMount() {
    const { id } = this.$route.params;
    if (id) {
      this.fetchRecipe(id);
    }
  },
  methods: {
    isEmpty,
    async fetchRecipe(id) {
      this.recipeLoading = true;
      try {
        const query = await db.collection('recipes').doc(id).get();
        if (query.exists) {
          this.recipe = normalizeRecipe(query);
          this.recipeForm = this.recipe;
        }
        this.recipeLoading = false;
      } catch (err) {
        this.recipeLoading = false;
        if (err.code) {
          this.errorMsg = handleErrorCode(err.code);
        } else {
          this.errorMsg = err;
        }
      }
    },
    async handleUpdateRecipe(recipeData) {
      try {
        const recipeId = recipeData.id;
        delete recipeData.id;
        delete recipeData.userId;
        delete recipeData.lastUpdated;
        delete recipeData.createdOn;

        let updatedRecipe = {
          lastUpdated: serverTimestamp(),
          ...recipeData,
        };

        const recipeRef = db.collection('recipes').doc(recipeId);
        await recipeRef.update(updatedRecipe);

        this.$swal({
          icon: 'success',
          title: `Recipe Updated!`,
          text: `${updatedRecipe.title}`,
        });
        this.$router.push({ name: 'Recipe', params: { id: recipeId } });
      } catch (err) {
        console.error(err);
        this.$swal({
          icon: 'error',
          title: 'Error Updating',
          text: 'There was an error updating the recipe, please try again',
        });
        if (err.code) {
          this.errorMsg = handleErrorCode(err.code);
        } else {
          this.errorMsg = err;
        }
      }
    },
  },
};
</script>

<style></style>
