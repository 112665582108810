const formatNewRecipe = ({ recipe, user, serverTimestamp }) => {
  let newRecipe = {
    createdOn: serverTimestamp(),
    lastUpdated: serverTimestamp(),
    userId: user.uid,
    ...recipe,
  };

  return newRecipe;
};

export default formatNewRecipe;
