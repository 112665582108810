<template>
  <div id="import-recipe">
    <div class="relative px-4 pt-16 pb-20 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
      <div class="relative mx-auto max-w-7xl">
        <div class="mb-4 text-center">
          <h2
            class="text-3xl font-extrabold leading-9 tracking-tight text-gray-900 sm:text-4xl sm:leading-10"
          >
            Import Recipe
          </h2>
          <p class="max-w-2xl mx-auto mt-3 text-xl leading-7 text-gray-500 sm:mt-4">
            Tired of scrolling through someones story and just want the recipe?
            <br />Enter the url, we'll try to parse it for you
          </p>
        </div>

        <div class="mb-4 overflow-hidden shadow sm:rounded-md">
          <div class="px-4 py-6 bg-white sm:p-8">
            <FormulateForm
              name="import-recipe-form"
              v-model="importForm"
              :schema="schema"
              @submit="fetchRecipe"
            />
          </div>
        </div>

        <div v-if="recipeLoading" class="text-center">
          <LoadingSvg />
        </div>
        <div v-else-if="recipeError && !recipeLoading && !recipe">
          <div class="p-4 mt-6 rounded-md bg-yellow-50">
            <div class="flex">
              <div class="flex-shrink-0">
                <svg class="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20">
                  <path
                    fill-rule="evenodd"
                    d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <div class="ml-3">
                <h3 class="text-sm font-medium leading-5 text-yellow-800">
                  Recipe can't be imported
                </h3>
                <div class="mt-2 text-sm leading-5 text-yellow-700">
                  <p>There was an error importing that recipe, please add manually</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="recipe">
          <div class="pt-10 mt-6 border-t-2 border-gray-100">
            <h4
              class="my-4 mb-8 text-3xl font-medium leading-7 tracking-tight text-center text-gray-900"
            >
              {{ recipe.title }}
            </h4>

            <div class="md:grid md:grid-cols-2 md:gap-8">
              <div class="overflow-hidden bg-white shadow sm:rounded-md">
                <h5
                  class="my-4 text-lg font-medium leading-7 tracking-tight text-center text-gray-500"
                >
                  Ingredients
                </h5>
                <ul>
                  <li v-for="(item, index) in recipe.ingredients" :key="index">
                    <div class="flex items-center px-4 py-4 sm:px-6">
                      <span class="mr-2 text-gray-400">{{ index + 1 }})</span>
                      <p>{{ item.item }}</p>
                    </div>
                  </li>
                </ul>
              </div>

              <div class="overflow-hidden bg-white shadow sm:rounded-md">
                <h5
                  class="my-4 text-lg font-medium leading-7 tracking-tight text-center text-gray-500"
                >
                  Directions
                </h5>
                <ul>
                  <li v-for="(item, index) in recipe.directions" :key="index">
                    <div class="flex items-center px-4 py-4 sm:px-6">
                      <span class="mr-2 text-gray-400">{{ index + 1 }})</span>
                      <p>{{ item.text }}</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="text-center">
            <p class="py-4">Do you want to save this recipe to your collection?</p>
            <button
              @click="saveRecipe"
              class="px-5 py-3 text-sm font-medium leading-6 text-blue-700 transition duration-150 ease-in-out rounded-md cursor-pointer bg-gray-50 hover:text-blue-500 focus:outline-none focus:shadow-outline"
            >
              Save Recipe
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
import LoadingSvg from '@/components/LoadingSvg.vue';
import useFormSchemas from '@/lib/formSchemas';
import { db, serverTimestamp } from '@/services/firebase';
import handleErrorCode from '@/util/handleErrorCode';
const { importFormSchema } = useFormSchemas();
const recipeApi = process.env.VUE_APP_RECIPE_API;

export default {
  name: 'ImportRecipe',
  metaInfo: {
    title: 'Import Recipe | Recipeazy',
  },
  components: {
    LoadingSvg,
  },
  data() {
    return {
      // TODO: check this url, has tags in ingredients
      // recipeUrl: 'https://www.delish.com/cooking/recipe-ideas/a30503020/braised-chicken-thighs-recipe/',
      importForm: {},
      recipe: null,
      recipeLoading: false,
      recipeError: false,
      schema: importFormSchema,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  methods: {
    reset() {
      this.recipe = null;
      this.recipeError = false;
      this.$formulate.reset('import-recipe-form');
    },
    async fetchRecipe() {
      this.recipeLoading = true;
      try {
        const request = await axios.get(
          `${recipeApi}fetch-recipe?url=${this.importForm.recipeUrl}`
        );
        const recipe = request.data;
        this.recipe = recipe;
        this.recipeLoading = false;
      } catch (err) {
        this.recipeError = true;
        this.recipeLoading = false;
      }
    },
    async saveRecipe() {
      try {
        let newRecipe = {
          category: '',
          createdOn: serverTimestamp(),
          description: '',
          directions: this.recipe.directions,
          ingredients: this.recipe.ingredients,
          lastUpdated: serverTimestamp(),
          title: this.recipe.title,
          userId: this.user.uid,
        };
        console.log('SAVING RECIPE', newRecipe);
        await db.collection('recipes').add(newRecipe);

        this.$swal({
          icon: 'success',
          title: `Recipe Added!`,
          text: `${newRecipe.title}`,
        });

        this.reset();
      } catch (err) {
        console.error('performAddRecipe', err);
        this.$swal({
          icon: 'error',
          title: 'Error Saving',
          text: 'There was an error adding the recipe, please try again',
        });
        this.errorMsg = err.code ? handleErrorCode(err) : err;
      }
    },
  },
};
</script>

<style></style>
