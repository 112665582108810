<template>
  <div class="add-recipe">
    <div class="max-w-screen-xl px-4 py-12 mx-auto sm:px-6 lg:py-16 lg:px-8">
      <div class="text-center">
        <h2 class="text-3xl font-extrabold leading-9 text-gray-900 sm:text-4xl sm:leading-10">
          Add Recipe
        </h2>
        <p class="mt-1 mb-2 text-sm leading-5 text-gray-500">
          Quickly add a recipe for future reference
        </p>
      </div>

      <div class="mt-6">
        <div class="px-4 sm:px-0">
          <transition name="fade" mode="out-in">
            <div v-if="errorMsg" class="p-4 my-4 border border-red-200 rounded-md bg-red-50">
              <div class="flex">
                <div class="flex-shrink-0">
                  <svg class="w-5 h-5 text-red-400" fill="currentColor" viewBox="0 0 20 20">
                    <path
                      fill-rule="evenodd"
                      d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
                <div class="ml-3">
                  <h3 class="text-sm font-medium leading-5 text-red-800">Error Adding Recipe</h3>
                  <div class="mt-2 text-sm leading-5 text-red-700">
                    <p>{{ errorMsg }}</p>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>

      <div class="mb-4 overflow-hidden shadow sm:rounded-md">
        <div class="px-4 py-6 bg-white sm:p-8">
          <FormulateForm
            name="add-recipe-form"
            v-model="recipeForm"
            :schema="schema"
            @submit="performAddRecipe"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
// import { cloneDeep } from 'lodash';
import { db, serverTimestamp } from '@/services/firebase';
import handleErrorCode from '@/util/handleErrorCode';
import useFormSchemas from '@/lib/formSchemas';
import formatNewRecipe from '@/util/formatNewRecipe';
const { addRecipeFormSchema } = useFormSchemas();

export default {
  name: 'AddRecipe',
  metaInfo: {
    title: 'Add Recipe | Recipeazy',
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  data() {
    return {
      errorMsg: '',
      recipeForm: {},
      schema: addRecipeFormSchema,
    };
  },
  methods: {
    reset() {
      this.errorMsg = '';
      this.$formulate.reset('add-recipe-form');
    },
    async performAddRecipe() {
      try {
        /* let newRecipe = {
          createdOn: serverTimestamp(),
          lastUpdated: serverTimestamp(),
          userId: this.user.uid,
          ...this.recipeForm,
        };*/
        const newRecipe = formatNewRecipe({
          user: this.user,
          recipe: this.recipeForm,
          serverTimestamp,
        });

        console.log('new recipe', newRecipe);

        await db.collection('recipes').add(newRecipe);
        this.$swal({
          icon: 'success',
          title: `Recipe Added!`,
          text: `${newRecipe.title}`,
        });

        this.$formulate.reset('add-recipe-form');

        // TODO fix: https://github.com/wearebraid/vue-formulate/issues/284#issuecomment-712163798
      } catch (err) {
        console.error('performAddRecipe', err);
        this.$swal({
          icon: 'error',
          title: 'Error Creating',
          text: 'There was an error creating the recipe, please try again',
        });
        this.errorMsg = err.code ? handleErrorCode(err) : err;
      }
    },
  },
};
</script>

<style></style>
