<template>
  <div class="my-recipes">
    <div class="relative px-4 pt-16 pb-20 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
      <div class="relative mx-auto max-w-7xl">
        <div class="text-center">
          <h2
            class="text-3xl font-extrabold leading-9 tracking-tight text-gray-900 sm:text-4xl sm:leading-10"
          >
            My Recipes
          </h2>
          <p class="max-w-2xl mx-auto mt-3 text-xl leading-7 text-gray-500 sm:mt-4">
            Any recipes you add will show up here
          </p>
        </div>

        <div v-if="userRecipes && userRecipes.length > 0">
          <div class="grid max-w-lg gap-5 mx-auto mt-12 lg:grid-cols-3 lg:max-w-none">
            <Recipe :recipe="recipe" :key="recipe.id" v-for="recipe in userRecipes">
              <template #deleteicon="{ deleteRecipe }">
                <div class="flex justify-end mb-2">
                  <button
                    @click="deleteRecipe"
                    class="inline-flex text-gray-400 transition duration-150 ease-in-out focus:outline-none focus:text-gray-500"
                  >
                    <svg class="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
                      <path
                        fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
              </template>
              <template #editbutton>
                <router-link
                  :to="'/edit/' + recipe.id"
                  class="relative inline-flex items-center px-4 py-2 text-sm font-medium leading-5 text-white bg-blue-700 border border-transparent rounded-md hover:bg-blue-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-700 active:bg-blue-700"
                >
                  Edit Recipe
                </router-link>
              </template>
            </Recipe>
          </div>
        </div>
        <div v-else>
          <div class="p-4 mt-8 border-l-4 border-yellow-400 bg-yellow-50">
            <div class="flex">
              <div class="flex-shrink-0">
                <svg class="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20">
                  <path
                    fill-rule="evenodd"
                    d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <div class="ml-3">
                <p class="text-sm leading-5 text-yellow-700">
                  You haven't added any recipes yet!
                  <router-link
                    to="/add"
                    class="font-medium text-yellow-700 underline transition duration-150 ease-in-out hover:text-yellow-600"
                  >
                    Add Recipe
                  </router-link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Recipe from '@/components/Recipe.vue';

export default {
  name: 'MyRecipes',
  metaInfo: {
    title: 'My Recipes | Recipeazy',
  },
  components: {
    Recipe,
  },
  computed: {
    ...mapState({
      userRecipes: (state) => state.userRecipes,
    }),
  },
};
</script>

<style></style>
